var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"F8DIRIvaJzgEcGj3rM1bj"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

if (window.Cookiebot?.consent?.statistics && process.env.NODE_ENV === "production" && !process.env.NEXT_PUBLIC_IS_CI) {
  Sentry.init({
    dsn: "https://2c16bf9f652a4db89abb8fc5509e02e9@o4505227742674944.ingest.sentry.io/4505227744247808",

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.01,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 0.25,

    // This sets the sample rate to be 0%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      new Sentry.Replay({
        // Additional Replay configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    // ignore ResizeObserver errors as they are more like warning log https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver#observation_errors
    ignoreErrors: ["ResizeObserver"],
  });
}

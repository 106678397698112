import getConfig from "next/config";

// We set default values here because without them getConfig will be undefined in the test environment
// (i.e. Jest). This happens because the Next.js server is not running in that environment.
// See: https://github.com/vercel/next.js/issues/4024#issuecomment-377837421
// and its parent issue for more information.
const { serverRuntimeConfig = {}, publicRuntimeConfig = {} } =
  getConfig() || {};

export const API_BASE = serverRuntimeConfig.apiBase;
export const PIANO_SCRIPT_URL = publicRuntimeConfig.pianoScriptUrl;
export const SUBSCRIPTION_UTILS_URL = publicRuntimeConfig.subscriptionUtilsUrl;
export const INTELLIGENCE_DB_UTILS_BASE_URL =
  serverRuntimeConfig.intelligenceDbUtilsUrl;
export const DATO_ENV = serverRuntimeConfig.datoEnv;

export const DATOCMS_BASE = publicRuntimeConfig.datoCmsBase;
export const MIXPANEL_TOKEN = publicRuntimeConfig.mixpanelToken;
export const PIANO_API_BASE = publicRuntimeConfig.pianoAPIBase;

export const CONTENT_API_URL = API_BASE + "/content";

export const GOOGLE_TAG_MANAGER_ID = "GTM-KH4T3PH";
export const GOOGLE_ANALYTICS_ID = "UA-127308923-1";
export const COOKIEBOT_ID = "33e59a16-c6e6-406b-8161-7e6b9693bc2e";

export const SIFTED_WP_SITE = "https://sifted.eu";

export const INTELLIGENCE_UNIT_GENERAL_INTEREST_MAILCHIMP =
  "https://sifted.us19.list-manage.com/subscribe/post?u=f728a14fdc520a63f329940f5&id=595e55ffc1";

export const DATOCMS_READ_ONLY_API_TOKEN =
  serverRuntimeConfig.datoCmsReadOnlyApiToken;
export const DATOCMS_READ_ONLY_API_TOKEN_HOMEPAGE =
  serverRuntimeConfig.datoCmsReadOnlyApiTokenHomepage;
export const DATOCMS_READ_ONLY_API_TOKEN_BRIEFINGS =
  serverRuntimeConfig.datoCmsReadOnlyApiTokenBriefings;
export const DATOCMS_READ_ONLY_API_TOKEN_EVENTS =
  serverRuntimeConfig.datoCmsReadOnlyApiTokenEvents;
export const DATOCMS_READ_ONLY_API_TOKEN_REPORTS =
  serverRuntimeConfig.datoCmsReadOnlyApiTokenReports;
export const DATOCMS_READ_ONLY_API_TOKEN_RANKINGS =
  serverRuntimeConfig.datoCmsReadOnlyApiTokenRankings;
export const DATOCMS_READ_ONLY_API_TOKEN_SITEWIDE_OPTIONS =
  serverRuntimeConfig.datoCmsReadOnlyApiTokenSitewideOptions;
export const DATOCMS_READ_ONLY_API_TOKEN_MEMBERS =
  serverRuntimeConfig.datoCmsReadOnlyApiTokenMembers;
export const DATOCMS_READ_ONLY_API_TOKEN_CONTENT =
  serverRuntimeConfig.datoCmsReadOnlyApiTokenContent;
export const DATOCMS_READ_ONLY_API_TOKEN_COMPANY =
  serverRuntimeConfig.datoCmsReadOnlyApiTokenCompany;

export const PIANO_API_BASE_URL = serverRuntimeConfig.pianoAPIBaseUrl;
export const PIANO_API_AID = serverRuntimeConfig.pianoAPIAID;
export const PIANO_API_TOKEN = serverRuntimeConfig.pianoAPIToken;

export const PIANO_SIFTED_FOR_ANGELS_TERM_ID =
  publicRuntimeConfig.pianoSiftedForAngelsTermId;
export const PIANO_SIFTED_FOR_ANGELS_OFFER_ID =
  publicRuntimeConfig.pianoSiftedForAngelsOfferId;
export const PIANO_SIFTED_PRO_TERM_ID =
  publicRuntimeConfig.pianoSiftedProTermId;
export const PIANO_SIFTED_PRO_OFFER_ID =
  publicRuntimeConfig.pianoSiftedProOfferId;

export const DATADOG_API_TOKEN = publicRuntimeConfig.datadogAPIToken;
export const DATADOG_APP_ID = publicRuntimeConfig.datadogAppID;
export const DATADOG_ENVIRONMENT = serverRuntimeConfig.datadogEnvironment;
export const DATADOG_API_KEY = serverRuntimeConfig.datadogAPIKey;

export const MENU_ITEMS_API_URL = API_BASE + "/structure/menus";

export const FUTURE_PROOF_PAGE_API_URL = (() => {
  const STAGING_PAGE_ID = "21930";
  const PROD_PAGE_ID = "34411";

  const pageId =
    process.env.STAGING === "true" ? STAGING_PAGE_ID : PROD_PAGE_ID;

  return CONTENT_API_URL + "/page/" + pageId;
})();

export const ARTICLE_API_URL = CONTENT_API_URL + "/article/";
export const RELATED_ARTICLES_API_URL = CONTENT_API_URL + "/relatedArticles/";
export const ARTICLE_FILTER_LIST_API_URL = CONTENT_API_URL + "/articles";
export const DATO_PROXY_API = API_BASE + "/dato";

export const INTELLIGENCE_PAGE_API_URL = (() => {
  const STAGING_PAGE_ID = "21932";
  const PROD_PAGE_ID = "34413";

  const pageId =
    process.env.STAGING === "true" ? STAGING_PAGE_ID : PROD_PAGE_ID;

  return CONTENT_API_URL + "/page/" + pageId;
})();

export const IS_CI = publicRuntimeConfig.isCI;

export const SINGLE_SEAT = "Single-Seat";
